$('[data-open-popup]').click(function(e){
    e.preventDefault();
    var targetDropdown =  $(this).attr('data-open-popup');
    $(this).toggleClass('show');

    $('.popup-overlay[id= ' + targetDropdown + ']').addClass('show');
    $('html').addClass('popup-open');
    $('.popup-overlay[id= ' + targetDropdown + '] .popup').css('transform', 'scale(1)');
});

$('.popup__close, .popup__cancel').click(function(){
    $('.popup-overlay').removeClass('show');
    $('html').removeClass('popup-open');
    $('.popup').css('transform', 'scale(0)');
});

$('.popup__stars-item').click(function () {
    $(this).addClass('popup__stars-item--selected');
    $(this).prevAll().addClass('popup__stars-item--selected');
    $(this).nextAll().removeClass('popup__stars-item--selected');
});
