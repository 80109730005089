$('.slider__upcoming-events').slick({
    slidesToShow: 3 ,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    prevArrow: $('#upcoming-prev'),
    nextArrow: $('#upcoming-next'),
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },{
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
    ]
});

$('.slider__about').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    fade: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
});

$('.slider__product-types').slick({
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    // dots: false,
    // fade: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 850,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },{
            breakpoint: 640,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
    ]

});

$('.slider__single').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    fade: true,
    prevArrow: $('#single-prev'),
    nextArrow: $('#single-next'),
});

$('.slider__about-boxes').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    prevArrow: $('#about-box-prev'),
    nextArrow: $('#about-box-next'),
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                arrows: true,
            }
        },
        {
            breakpoint: 830,
            settings: {
                slidesToShow: 2,
                arrows: true,
            }
        },{
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
                arrows: true,
            }
        },
    ]
});

$('.slider__other-vacancies').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    prevArrow: $('#other-prev'),
    nextArrow: $('#other-next'),
    responsive: [
        {
            breakpoint: 830,
            settings: {
                slidesToShow: 2,
                arrows: true,
            }
        },{
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
                arrows: true,
            }
        },
    ]
});

$('.slider__wishlist').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    prevArrow: $('#wishlist-prev'),
    nextArrow: $('#wishlist-next'),
    responsive: [
        {
            breakpoint: 830,
            settings: {
                slidesToShow: 2,
            }
        },{
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
});

$('.slider__symbols').slick({
    slidesToShow: 7,
    slidesToScroll: 7,
    arrows: true,
    dots: false,
    prevArrow: $('#symbol-prev'),
    nextArrow: $('#symbol-next'),
    speed: 500,
    responsive: [
        {
            breakpoint: 930,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
            }
        },
        {
            breakpoint: 730,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
    ]
});

var syncLarge = $('.slider__sync-large');

syncLarge.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    fade: true,
    prevArrow: $('#large-sync-prev'),
    nextArrow: $('#large-sync-next'),
    lazyLoad: 'ondemand',
}).slickLightbox({
    itemSelector: '> div > div > div > ul > li a'
});

var sliderLogos = $('.slider__fire-logos');
var sliderText = $('.slider__fire-text');

sliderLogos
    .slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        centerPadding: '250px',
        draggable: false,
        centerMode: true,
        appendArrows: $('.slider__arrows-alt'),
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        // loop: true,
        focusOnSelect: true,
        asNavFor: '.slider__fire-text',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: '0px',
                }
            },
            {
                breakpoint: 640,
                settings: {
                    centerPadding: '0px',
                    centerMode: false,
                    slidesToShow: 1,
                }
            },
        ]
    });

sliderText.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    fade: true,
    adaptiveHeight: true,
    asNavFor: '.slider__fire-logos',
});

$('.slider__brands').slick({
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    dots: false,
    prevArrow: $('#brand-prev'),
    nextArrow: $('#brand-next'),
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 850,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },{
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
    ]
});

$('.slider__best-sellers').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    // centerMode: true,
    infinite: false,
    appendArrows: $('.slider__alt-arrows'),
    prevArrow: $('#bestselling-prev'),
    nextArrow: $('#bestselling-next'),
    responsive: [
        {
            breakpoint: 1130,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            }
        },{
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
});