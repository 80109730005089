var checkbox = $('.select-brochure');

function checkLocalStorage() {
    var selectedCheckboxes;

    if(localStorage.getItem('selected-checkboxes') === null) {
        selectedCheckboxes = [];
    } else {
        selectedCheckboxes = JSON.parse(localStorage.getItem('selected-checkboxes'));
    }

    return selectedCheckboxes;
}

function addValueToArray(value) {

    var checked = checkLocalStorage();

    checked.push(value);

    localStorage.setItem('selected-checkboxes', JSON.stringify(checked));

}

function removeValueFromArray(value) {
    var checked = checkLocalStorage();

    checked.splice($.inArray(value, checked), 1);

    localStorage.setItem('selected-checkboxes', JSON.stringify(checked));
}

function createSelectedBrochuresItem(value) {
    var item = document.createElement('div'),
        // itemInput = document.createElement('input'),
        itemInnerHTML = '<div class="inner"><input class="selected-input" value="'+ value +'" hidden="hidden"><p>' + value + '</p> <button class="delete-selected"></button></div>';

    item.className = 'form__brochure';
    //
    item .setAttribute('data-selected', value);
    // itemInput.setAttribute('hidden', 'hidden');


    item.innerHTML = itemInnerHTML;

    $('.form__brochures').append(item);
}

function removeSelectedBrochuresItem(value) {
    $('.form__brochures').find('[data-selected="' + value + '"]').remove();
}

function changeLabelText(value, newTextName) {
    $('label[for="' + value, + '"]').html(newTextName);
}

var count = 1;

checkbox.change(function(){
    var checkboxValue = $(this).val(),
        seoValue = checkboxValue.toLowerCase().replace(/\s/g, '-');

    if($(this).is(':checked')) {

        addValueToArray(checkboxValue);

        changeLabelText(seoValue, 'Selected', + ' (' + count + ')');

        createSelectedBrochuresItem(checkboxValue);
    } else {
        removeValueFromArray(checkboxValue);

        changeLabelText(seoValue, 'Select');

        removeSelectedBrochuresItem(checkboxValue);
    }
});

var getChecked = checkLocalStorage();


checkbox.each(function(index, value){
    var checkboxValue = $(this).val(),
        seoValue = checkboxValue.toLowerCase().replace(/\s/g, '-');

    console.log(checkboxValue);

    if($.inArray(checkboxValue , getChecked) !== -1) {

        $('input[value="' + checkboxValue + '"]').prop('checked', true);

        changeLabelText(seoValue, 'Selected' + '<span> (' + count + ')</span>');

        count++

        createSelectedBrochuresItem(checkboxValue);

    } else {
        $('input[value="' + checkboxValue + '"]').prop('checked', false);
    }
});

$('.delete-selected').click(function(e){
   e.preventDefault();

   var selectedValue = $(this).parent().find('input').val(),
       seoValue = selectedValue.toLowerCase().replace(/\s/g, '-');

   $('.page__brochures').parent().find('input[value="' + selectedValue + '"]').prop('checked', false);

    changeLabelText(seoValue, 'select');

   $(this).parent().parent().remove();

    removeValueFromArray(selectedValue);
});

