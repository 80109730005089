$('#form-button-search').click(function(e){
    e.preventDefault();

    $(this).text('Search Again');

    $('#search-results').fadeIn('slow');
    $('#tech-cta').fadeOut('slow');

    if($('#search-results').is(':visible')) {
        $('.form--product-search').addClass('form--product-search-showing')
    }

    $('.slider__single-gallery').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: true,
        dots: false,
        appendArrows: $('.slider__arrows-alt'),
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
    });
});