var dataUrl = $('body').attr('data-base');


function formatState (state) {
    if (!state.id) {
        return state.text;
    }
    var $substring = state.text.substring(0, 2);

    var $state = $(
        '<span>' + $substring +'</span>'
    );

    return $state;
};

$(".header__languages").select2({
    templateSelection: formatState,
    containerCssClass: "header__languages",
    dropdownCssClass: "header__languages",
    minimumResultsForSearch: -1
});

$('.header__languages').on("change",function(){

    var value = $(this).val();

    $('.header__languages').not($(this)).each(function() {
        $(this).val(value);
        $(this).trigger('change.select2');
    });
});


$('.enquire').click(function(e){
    e.preventDefault();

    $('.article__section--enquire').slideDown('slow');
});

function getElementHeight(className) {
    var elementHeight =  $(className);

    return elementHeight.height();
}


function trimSubString(elementString, startNum, endNum) {

    return elementString.substring(startNum, endNum);
}

var mobileMenu = $('.mobile');

$('.mobile__link--drilldown-button').click(function(e){
    e.preventDefault()

    $(this).parent('.mobile__link--drilldown').addClass('mobile__link--drilldown-open');

    if($('.mobile__link--drilldown-open').is(':visible')) {
        mobileMenu.css('overflow-y', 'hidden');
        $('.mobile__inner').css('overflow-y', 'hidden');
    }
});


$('.mobile__back').click(function() {
    $(this).parent('.mobile__drilldown').parent('.mobile__link--drilldown').removeClass('mobile__link--drilldown-open');

    if(!$('.mobile__link--drilldown-open').is(':visible')) {
        mobileMenu.css('overflow-y', 'auto');
        $('.mobile__inner').css('overflow-y', 'auto');
    }
});

$('.header__hamburger').click(function() {
    $('.mobile').css('transform', 'translate3d(0, 0, 0)');
});

$('.header__top-link--dropdown').on('mouseenter', function(){
    $(this).toggleClass('active');
});

$('.mobile__close').click(function() {
    mobileMenu.css('transform', 'translate3d(100%, 0, 0)');
    $('.mobile__link--drilldown').removeClass('mobile__link--drilldown-open');

    mobileMenu.css('overflow-y', 'auto');
    $('.mobile__inner').css('overflow-y', 'auto');
});

$(window).scroll(function(){
    if($(window).scrollTop() > 0) {
        $('.header').addClass('header--state2');
    } else {
        $('.header').removeClass('header--state2');
    }

    if($(window).scrollTop() > 39) {
        if($('.skiptranslate').is(':visible')) {
            $('.header').css('top', '39px');
        }
    } else {
        if($('.skiptranslate').is(':visible')) {
            $('.header').css('top', '0');
        }
    }
});

$('.header__nav-item').on('mouseenter', function(){
    $('.header__nav-item').removeClass('header__nav-item--active');

    $(this).addClass('header__nav-item--active');
});

$('.dropdown').on('mouseleave', function(){
    $('.header__nav-item').removeClass('header__nav-item--active');
});

$('.header__cart').click(function (){
    $('.basket').css('transform', 'translate3d(0, 0, 0)');
    $('html').addClass('basket-open');
    $('.basket-overlay').addClass('show');
});

$('.basket__close').click(function (){
    $('.basket').css('transform', 'translate3d(500px, 0, 0)');
    $('html').removeClass('basket-open');
    $('.basket-overlay').removeClass('show');
});

$('.basket-overlay').click(function (){
    $('.basket').css('transform', 'translate3d(500px, 0, 0)');
    $('html').removeClass('basket-open');
    $('.basket-overlay').removeClass('show');
});

$('.dropdown__link').on('mouseenter', function(){
    $('.dropdown__wrapper').css('display', 'none');
    $('.dropdown__link').removeClass('dropdown__link--active');

    $(this).addClass('dropdown__link--active');

    var dropdownTarget = $(this).attr('data-dropdown-target');

    $('[data-dropdown=' + dropdownTarget +']').css('display', 'flex');

});

