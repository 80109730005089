$('.footer__dropdown-button').click(function(e){
    e.preventDefault();

    $(this).toggleClass('active');

    $(this).parent('.footer__link--dropdown').find('.footer__links--dropdown').slideToggle();
});

function addAccordionFunctionality(eventButton, nextElement) {
    $(eventButton).click( function(e){
        e.preventDefault();

        console.log(e.target);

        if ($(this).hasClass('active') ) {
            $(this).removeClass('active');
            $(this).next(nextElement).removeClass('current');
            $(this).next(nextElement).slideUp('slow');
        } else {
            $(eventButton + '.active').removeClass('active');
            $(nextElement + '.current').removeClass('current');
            $(nextElement).slideUp('slow');

            $(this).addClass('active');
            $(this).next(nextElement).addClass('current');
            $(this).next(nextElement).slideDown('slow');
        }
    });
}

$(window).resize(function(){
    if($(window).width() < 640) {
        addAccordionFunctionality('.footer__title--links', '.footer__links--top-level');
        addAccordionFunctionality('.footer__title--image', '.footer__images');
    }
});

if($(window).width() < 640) {
    addAccordionFunctionality('.footer__title--links', '.footer__links--top-level');
    addAccordionFunctionality('.footer__title--image', '.footer__images');
}