$('.step__panels').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: false,
    fade: true,
    swipe: false,
    // asNavFor: '.step__tabs',
    adaptiveHeight: true,
});

$('.step__tabs')
.on('init', function (event, slick) {
    $('.step__tabs .slick-slide.slick-current').addClass('is-active');
})
.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    focusOnSelect: true,
    swipe: false,
    infinite: false,
    speed: 300,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 501,
            settings: {
                slidesToShow: 1,
                fade: true,
                asNavFor: '.step__panels',
            }
        },
    ]
});

$('.step__tabs').on('click', '.step__tab', function (event) {
    event.preventDefault();
    $('.step__tab').removeClass('is-active');

    $(this).addClass('is-active');

    var goToSingleSlide = $(this).data('slick-index');

    $('.step__panels').slick('slickGoTo', goToSingleSlide);
});

$('.step__panels').on('afterChange', function (event, slick, currentSlide) {
    $('.step__tabs').slick('slickGoTo', currentSlide);
    var currrentNavSlideElem = '.step__tabs  .slick-slide[data-slick-index="' + currentSlide + '"]';
    $('.step__tabs .slick-slide.is-active').removeClass('is-active');
    $(currrentNavSlideElem).addClass('is-active');
});

$('button[data-slide]').click(function(e) {
    e.preventDefault();
    var slideno = $(this).data('slide');
    $('.step__panels').slick('slickGoTo', slideno - 1);
})

$('.step__step-images').each( function() {
    $(this).slick( {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        arrows: true,
        fade: true,
        appendArrows: $(this).parent('.step__slider').find('.step__arrows'),
    }).on('afterChange', function(e) { e.stopPropagation() })
});

$('.horizontal-scroll--drag-icon').overlayScrollbars({ });