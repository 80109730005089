var $layout = $(".shop__products");
var $list = $(".shop__view-item--list");
var $grid = $(".shop__view-item--grid");

$list.click(function () {
    $(this).addClass("active");
    $grid.removeClass("active");

    $layout.removeClass("grid").addClass("list");
});

$grid.click(function () {
    $(this).addClass("active");
    $list.removeClass("active");

    $layout.removeClass("list").addClass("grid");
});

if($(window).width() <= 640) {
    $layout.removeClass("list").addClass("grid");
}

$(window).resize(function(){
    if($(window).width() <= 640) {
        $layout.removeClass("list").addClass("grid");
    }
});

if($(window).width() < 640) {
    $layout.removeClass('shop__view-item--list');
}

$(window).resize(function(){
    if($(window).width() < 640) {
        $layout.removeClass('shop__view-item--list');
    }
});

$('.filter__button').click(function(e){
    e.preventDefault();

    $(this).toggleClass('filter__button--active');
    $(this).parent('.filter__item').find('.filter__view-more').slideToggle();
    $(this).parent('.filter__item').find('.product__colours--filter').slideToggle();
});

$('.view-more').click(function(e){
    e.preventDefault();

    var txt = $(this).parent().hasClass("show") ? "+ View More" : "- View Less";

    $(this).text(txt);

    $(this).parent('.filter__view-more').toggleClass('show');
});

$('.shop__open-filters').click(function(e){
    e.preventDefault();

    $('html').addClass('filters-open');

    $('.shop__filters').css('transform', 'translate3d(0,0,0)');
});

$('.close').click(function(e){
    e.preventDefault();

    $('html').removeClass('filters-open');

    $('.shop__filters').css('transform', 'translate3d(-280px,0,0)');
});

$('.filters-overlay').click(function(){
    $('html').removeClass('filters-open');

    $('.shop__filters').css('transform', 'translate3d(-280px,0,0)');
});

$('.product__add-to-wishlist').click(function(){
    $(this).toggleClass('product__add-to-wishlist--active');
});

var compareBar = document.querySelector('.shop__selected-items');

function generateCompareItems() {
    var numberOfSpacesLeft = 4;

    if(numberOfSpacesLeft === 4) {
        $(".shop__compare").css('display', 'block');
    } else {
        $(".shop__compare").css('display', 'none');
    }

    if(!$(this).is(":checked")) {
        $(this).next('label').html('<strong>Compare</strong> <span class="compare-amount">up to 4</span>')
    }

    $(".checkbox__input--compare:checked").each(function(){

        var text = $(this).attr('data-title'),
            image =  $(this).attr('data-image'),
            productId =  $(this).attr('data-product-id')

        if(text.length > 37) {
            text = text.substr(0, 30) + "...";
        }

        var selectedHtml = '<li class="shop__selected-item"><img class="image" src="'+ image +'" alt="'+ text +'"><div class="title">'+ text +'</div><button data-delete-target="' + productId + '" class="delete"></button></li>';

        $(".shop__selected-items").append(selectedHtml);

        numberOfSpacesLeft--;

        $(this).next('label').html('<strong>Compare</strong> <span class="compare-amount">'+ (4 - numberOfSpacesLeft) + ' of 4</span>')
    });


    $(".shop__selected-items").attr('data-no-of-items', numberOfSpacesLeft);

    for(j = 0; j < numberOfSpacesLeft; j++) {
        $(".shop__selected-items").append('<li class="shop__selected-item shop__selected-item--empty"></li>');
    }
}

$(".checkbox__input--compare:not('.checkbox__input--accept')").click(function() {
    $(".shop__selected-items").html('');

    $('.product__compare .checkbox__label').html('<strong>Compare</strong> <span class="compare-amount">up to 4</span>');

    generateCompareItems();

    if(parseInt($(".shop__selected-items").attr('data-no-of-items')) === 4) {
        document.querySelector('.shop__compare').style.display = 'none';

        // $('.product__compare .checkbox__label').html('<strong>Compare</strong> <span class="compare-amount">up to 4</span>');
    }
});

document.addEventListener('click', function(event) {
    if(event.target.classList.contains('delete')) {
        var spaceLeft = $(".shop__selected-items").attr('data-no-of-items');

        spaceLeft++

        $(".shop__selected-items").attr('data-no-of-items', spaceLeft);

        var targetProduct = event.target.getAttribute('data-delete-target');
        //
        event.target.parentElement.classList.add('shop__selected-item--empty');
        event.target.parentElement.innerHTML = '';
        //
        $('[data-product-id='+ targetProduct +']').prop('checked', false);
        //
        $('[data-product-id='+ targetProduct +']').next('label').html('<strong>Compare</strong> <span class="compare-amount">up to 4</span>');

        $(".shop__selected-items").html('');

        generateCompareItems();

        if(spaceLeft === 4) {
            document.querySelector('.shop__compare').style.display = 'none';
        }
    }
});

$(".shop__mobile-compare-button").click( function() {
    $(this).toggleClass('active');
    $(".shop__compare-wrapper").slideToggle();
});

$(".clear").click(function(e) {
    e.preventDefault();

    $(".shop__selected-items").html('');

    document.querySelector('.shop__compare').style.display = 'none';

    $('.checkbox__input--compare').prop('checked', false);

    $('.product__compare .checkbox__label').html('<strong>Compare</strong> <span class="compare-amount">up to 4</span>');
});