if($(window).width() < 640 && $('.read-more').height() > 470) {
    $('.read-more').addClass('active');
} else {
    $('.read-more').removeClass('active');
}

$(window).resize(function() {
    if($(window).width() < 640 && $('.read-more').height() > 470) {
        $('.read-more').addClass('active');
    } else {
        $('.read-more').removeClass('active');
    }
});

$('#read-more').click(function(){
    $(this).parent('.page__read-more').toggleClass('page__read-more--active');

    var btnText = $('.page__read-more--active').is(':visible') ? 'Read Less' : 'Read More';

    $(this).text(btnText);
});