var $button = $('.show-password');
var $input = $('.form__input--password');

$button.on('click', function() {

    $button.toggleClass('active');

    if ($input.attr('psswd-shown') == 'false') {
        $input.removeAttr('type');
        $input.attr('type', 'text');

        $input.removeAttr('psswd-shown');
        $input.attr('psswd-shown', 'true');
    } else {
        $input.removeAttr('type');
        $input.attr('type', 'password');

        $input.removeAttr('psswd-shown');
        $input.attr('psswd-shown', 'false');
    }

});