$(document).ready(function() {
    $('.lazy').Lazy({
        effect: "fadeIn",
        effectTime: 500,
        threshold: 0,

        afterLoad: function (element) {
            element.removeClass('lazy-background');
            element.removeClass('lazy-loader');

            if ($(window).width() > 850) {
                if($('.news__half--slider').length > 0) {
                    var sidebar = new StickySidebar('.news__half--slider', {
                        topSpacing: 120,
                        bottomSpacing: 25,
                        containerSelector: '.news__sticky-contain',
                        innerWrapperSelector: '.news__slider',
                    });
                }
            }
        },
    });

    if ($(window).width() > 850) {
        if($('.news__half--slider').length > 0) {
            var sidebar = new StickySidebar('.news__half--slider', {
                topSpacing: 120,
                bottomSpacing: 25,
                containerSelector: '.news__sticky-contain',
                innerWrapperSelector: '.news__slider',
            });
        }
    }

    // $('.filters__box--scroll').overlayScrollbars({});

    AOS.init();

    $('[data-fade-in]').on('inview', function(event, isInView) {
        if (isInView) {
            $(this).css('opacity', '1');
        } else {
            $(this).css('opacity', '0');
        }
    });

    $('.page__map').click(function() {
        $(this).css('display', 'none');

        $('.page__form').css('display', 'block');
    });

    $('.basket__remove').click(function() {
        $(this).parents('.basket__product').remove();
    });

    $('.file-upload').change(function () {
        var value = $(this).val();
        var newValue = value.replace(/^.*\\/, "");
        var customFileUpload = $('#new-file-name');

        $(customFileUpload.html(newValue));
    });

    $('#back-to-map').click(function() {
        $('.page__map').css('display', 'block');
        $('.page__form').css('display', 'none');
    });

    new universalParallax().init({
        speed: 12.0,
    });
});