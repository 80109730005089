$(document).ready(function() {

    $('.horizontal-scroll').overlayScrollbars({});

    $('.product-reviews').click(function(){
        $('.tabs__title').removeClass('tabs__title--active');
        $('.tabs__panel').removeClass('tabs__panel--active');

        $('#reviews').addClass('tabs__title--active');
        $('[data-tab="reviews"]').addClass('tabs__panel--active');

    });

    $('#add-to-whishlist').click(function(){
        $(this).toggleClass('active');

        ($('.active').is(':visible'))? $(this).text('Added To Wishlist')  : $(this).text('Add To Wishlist');
    });

    $('.size').click(function() {
        if(!$(this).hasClass('size--disabled')) {
            var selectedSize = $(this).attr('data-size');

            $('.size').removeClass('active');

            $(this).addClass('active')

            $('.qty__input').attr('name', selectedSize);
            $('.qty__input').val(0);
        }
    });

});