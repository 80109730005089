var spinner = $('.qty__input');
var buttonUp = $('.qty__button--up');
var buttonDown = $('.qty__button--down');

buttonUp.click(function() {
    var value = parseInt($(this).parents('.qty').find('.qty__input').val(), 10);

    value = isNaN(value) ? 0 : value;
    value++;
    $(this).parents('.qty').find('.qty__input').val(value);
});

buttonDown.click(function() {
    var value = parseInt($(this).parents('.qty').find('.qty__input').val(), 10);

    if(value !== 0) {
        value = isNaN(value) ? 0 : value;
        value--;
        $(this).parents('.qty').find('.qty__input').val(value);
    }

});

$('.qty__button').click(function() {
    var value = parseInt($(this).parents('.qty').find('.qty__input').val(), 10);

    if(value > 0) {
        $(this).parents('.qty').find('.qty__input').prev().css('opacity', 1);
    } else {
        $(this).parents('.qty').find('.qty__input').prev().css('opacity', 0.3);
    }

});