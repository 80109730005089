$('.accordion__title').click(function(){
    if ( $(this).hasClass('accordion__title--active') ) {
        $(this).removeClass('accordion__title--active');
        $(this).next('.accordion__content').slideUp('slow');
    } else {
        $('.accordion__title.accordion__title--active').removeClass('accordion__title--active');
        $('.accordion__content').slideUp('slow');

        $(this).addClass('accordion__title--active');
        $(this).next('.accordion__content').slideDown('slow');
    }
});

$('.question__top').click(function(){
    if ( $(this).hasClass('question__top--active') ) {
        $(this).removeClass('question__top--active');
        $(this).next('.question__content').slideUp('slow');
    } else {
        $('.question__top.question__top--active').removeClass('question__top--active');
        $('.question__content').slideUp('slow');

        $(this).addClass('question__top--active');
        $(this).next('.question__content').slideDown('slow');
    }
});