
$('#custom-product-scroll').overlayScrollbars({});
$('#custom-main-scroll').overlayScrollbars({});
$('#copy-scrollbar').overlayScrollbars({});

$('#custom-aside-title').click(function() {
    $(this).toggleClass('active');

    if($('.active').is(':visible')) {
        $('.custom__sidebar').css('height', '100%');
    } else {
        $('.custom__sidebar').css('height', '69px');
    }
});

$('.slider__logos--logo').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    dots: false,
    prevArrow: $('#logo-prev'),
    nextArrow: $('#logo-next'),
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },{
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
    ]
});

$('.slider__logos--text').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    dots: false,
    prevArrow: $('#text-prev'),
    nextArrow: $('#text-next'),
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },{
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
    ]
});

$('.checkbox__input--personalise-type').change(function() {
    var personaliseType = $(this).val(),
        personaliseImageExample = $(this).attr('data-image');

    $('.custom__example').css({'opacity': '1', 'background-image': 'url(' + personaliseImageExample + ')'});

    $('#personalisation-type-text').text(personaliseType + ' Example Preview');

});

$('.select-logo-type').change(function() {
    if($(this).val() === 'Image') {
        $('.custom__section--image').css({'max-height': '100%', 'margin-bottom': '31px'});
    } else {
        $('.custom__section--image').css({'max-height': '0', 'margin-bottom': '0'});
    }

    if($(this).val() === 'Text') {
        $('.custom__section--text').css({'max-height': '100%', 'margin-bottom': '31px'});
    } else {
        $('.custom__section--text').css({'max-height': '0', 'margin-bottom': '0'});
    }
});

$('.radio').change(function() {
    $(this).parents('.custom__section').find('.custom__inner').removeClass('custom__inner--active');
    $(this).parents('.custom__section').find('.checkbox label').text('Select');

    $(this).parents('.custom__block, .slick-slide').find('.custom__inner').addClass('custom__inner--active');
    $(this).parents('.custom__block, .slick-slide').find('.checkbox label').text('Selected');
});

$('.checkbox-positions').change(function() {
    $('.checkbox-positions').each(function(input){
        if($(this).is(':checked')) {
            $(this).parents('.custom__block').find('.custom__inner').addClass('custom__inner--active');
            $(this).parents('.custom__block').find('.checkbox label').text('Selected');

        } else {
            $(this).parents('.custom__block').find('.custom__inner').removeClass('custom__inner--active');
            $(this).parents('.custom__block').find('.checkbox label').text('Select');
        }
    });
});

$('#clear-all').click(function(e) {
    e.preventDefault();

    $('.custom__checkbox input').prop('checked', false);
    $('.custom__checkbox .checkbox__label').text('Select');
    $('.custom__inner').removeClass('custom__inner--active');

    $('.custom__section--image').css({'max-height': '0', 'margin-bottom': '0'});
    $('.custom__section--text').css({'max-height': '0', 'margin-bottom': '0'});
});

function select2CustomResults(element, templateResults) {
    element.each(function(){

        if($(this).hasClass('select2-hidden-accessible')) {
            $(this).removeClass('select2-hidden-accessible');
        }

        var _class  = $(this).attr('class');
        var _dclass = $(this).attr('dropdown-class');

        $(this).select2({
            templateResult: templateResults,
            containerCssClass: _class,
            dropdownCssClass: _dclass,
            minimumResultsForSearch: 10
        });
    });
}

function addFonts(opt) {
    if (!opt.id) {
        return opt.text;
    }
    var optimage = $(opt.element).attr("data-image");
    var text = $(opt.element).attr("data-text");
    if (!optimage) {
        return opt.text;
    } else {
        var $opt = $(
            '<span class="content"><img src="' + optimage + '" alt="' + optimage + '"/></span>'
        );
        return $opt;
    }
}

function addColour(opt) {
    if (!opt.id) {
        return opt.text;
    }
    var optimage = $(opt.element).attr("data-image");

    if (!optimage) {
        return opt.text;
    } else {
        var $opt = $(
            '<span class="content content--colour"><img src="' + optimage + '" alt="' + optimage + '"/>' + opt.text +'</span>'
        );
        return $opt;
    }
}

select2CustomResults($(".form__input--select-fonts"), addFonts);
select2CustomResults($(".form__input--select-colours"), addColour);

var numberOfSelected = $('#number-selected'),
    copySelect = $('.checkbox__input--copy');


copySelect.change(function() {
    var numberSelected = 0;

    $(this).next('label').text('Select');

    $(".checkbox__input--copy:checked").each(function(){

        if($(this).is(":checked")) {
            $(this).next('label').text('Selected');
        }

        numberSelected++;
    });

    $('.number-selected').text(numberSelected);
});


var numberOfColourOptions = 1;

function createNewColourItem(colourOptionNumber) {

    var listItem = document.createElement('li');
    listItem.className = 'colour__item';

    listItem.innerHTML = '<div class="custom__section-title custom__section-title--subtitle">Colour <span class="number">' + colourOptionNumber + '</span> (Optional)</div>' +
        '<div class="colour__wrapper">' +
            '<div class="colour__inputs">' +
                '<div class="form__group form__group--no-m"> ' +
                    '<label for="colour-name-' + colourOptionNumber +'" class="form__label">Name</label>  ' +
                    '<input type="text" name="name-' + colourOptionNumber +'" id="colour-name-' + colourOptionNumber +'" class="form__input" placeholder="e.g. Red">' +
                '</div>' +
                '<div class="form__group form__group--no-m"> ' +
                    '<label for="pantone-colour-' + colourOptionNumber +'" class="form__label">Pantone Colour</label> ' +
                    '<input type="text" name="pantone-colour-' + colourOptionNumber +'" id="pantone-colour-' + colourOptionNumber +'" class="form__input" placeholder="e.g. 19-1763 TCX Formula One">' +
                '</div>' +
            '</div> ' +
            '<ul class="colour__options">' +
                '<li class="colour__option-add"><button class="add"> <img src="/dist/images/site/plus-icon.svg" alt=""></button></li> ' +
                '<li class="colour__option-remove"><button class="remove">  <img src="/dist/images/site/bin-icon.svg" alt=""></button></li>' +
            '</ul>' +
        '</div>';

    return listItem;
}
$('form').submit(function(e){
    e.preventDefault();
});

document.addEventListener('click', function(event) {

    console.log(event.target.parentElement);
    if(event.target.parentElement.classList.contains('add')) {

        numberOfColourOptions++;

        var newItem = createNewColourItem(numberOfColourOptions);

        document.querySelector('.colour').append(newItem);
    }

    if(event.target.parentElement.classList.contains('remove')) {

        event.target.parentElement.parentElement.parentElement.parentElement.parentElement.remove();
    }
});
