var syncLarge = $('.slider__news-large');
var syncSmall = $('.slider__news-small');

syncLarge
    .on('init', function (event, slick) {
        $('.slider__news-large .slick-slide.slick-current').addClass('is-active');
    })
    .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        fade: true,
        adaptiveHeight: true,
        prevArrow: $('#large-sync-prev'),
        nextArrow: $('#large-sync-next'),
        draggable: false,
        asNavFor: '.slider__news-small',
        lazyLoad: 'ondemand',
    }).slickLightbox({
    itemSelector: '> div > div > div > a'
});

syncSmall.on('afterChange', function (event, slick, currentSlide) {
    syncLarge.slick('slickGoTo', currentSlide);
    var currrentNavSlideElem = '.slider__news-large  .slick-slide[data-slick-index="' + currentSlide + '"]';
    $('.slider__news-large .slick-slide.is-active').removeClass('is-active');
    $(currrentNavSlideElem).addClass('is-active');
});

syncLarge.on('click', '.slick-slide', function (event) {
    event.preventDefault();
    var goToSingleSlide = $(this).data('slick-index');

    syncSmall.slick('slickGoTo', goToSingleSlide);
});

syncSmall.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    // lazyLoad: "ondemand",
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
            }
        },
    ]
});
