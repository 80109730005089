$(document).ready(function(){
    $('.goRight').on('click', function(){
        $('.login__box').removeClass('showing');

        $('#slideBox').animate({
            'marginLeft' : '0'
        });
        $('.topLayer').animate({
            'marginLeft' : '100%'
        });

        $(this).parents('.login__box').addClass('showing')
    });

    $('.goLeft').on('click', function(){
        $('.login__box').removeClass('showing');

        $('#slideBox').animate({
            'marginLeft' : '50%'
        });
        $('.topLayer').animate({
            'marginLeft': '0'
        });
        $(this).parents('.login__box').addClass('showing')
    });
});