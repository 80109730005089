var tabsTitle = $('.tabs__title'),
    tabsPanel = $('.tabs__panel'),
    tabsSelected = $('.tabs__selected');

$('.tabs__title:first-of-type').addClass('tabs__title--active');
$('.tabs__panel:first-of-type').addClass('tabs__panel--active');

tabsTitle.click(function(){

    var getAttr = $(this).attr('data-target-tab');

    tabsTitle.removeClass('tabs__title--active');
    $(this).addClass('tabs__title--active');
    $('.tabs__panel').css('display', 'none');
    $('[data-tab= ' + getAttr + ']').css('display', 'flex');
});

$('.tabs__mobile-title').click( function(e){
    e.preventDefault();

    if ( $(this).hasClass('active') ) {
        $(this).removeClass('active');
        // $(this).next('.wmm-accordion__content').removeClass('current');
        $(this).next('.tabs__inner').slideUp('slow');
    } else {
        $('.tabs__mobile-title.active').removeClass('active');
        $('.tabs__inner').slideUp('slow');

        $(this).addClass('active');
        $(this).next('.tabs__inner').slideDown('slow');
    }
});